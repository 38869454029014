import React from "react"
import { HashLink } from "react-router-hash-link"

export default function Footer() {
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="FireJob-Footer">
                            <div className="FireJob_footer-logo"><img src="images/logo.png" /></div>
                            <div className="FireJob_footer-text">
                                <p>TBNR LLC is proud to be an Equal Opportunity Employer. We celebrate diversity and are committed to an inclusive environment.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1">
                        <div className="FireJob_footer-nav">
                            <h3>SHOP</h3>
                            <ul>
                                <li><a href="https://firemerch.com/" target="_blank">Fire Merch</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="FireJob_footer-nav">
                            <h3><img src="images/yt.png" />&nbsp;&nbsp;TOP CHANNELS</h3>
                            <ul>
                                <li><a href="https://www.youtube.com/@PrestonYT" target="_blank">PrestonYT</a></li>
                                <li><a href="https://www.youtube.com/@PrestonPlayz" target="_blank">PrestonPlayz</a></li>
                                <li><a href="https://www.youtube.com/@TBNRfrags" target="_blank">TBNRFrags</a></li>
                                <li><a href="https://www.youtube.com/@BriannaYT" target="_blank">BriannaYT</a></li>
                                <li><a href="https://www.youtube.com/@BriannaPlayz" target="_blank">BriannaPlayz</a></li>
                                <li><a href="https://www.youtube.com/@PrestonRoblox" target="_blank">PrestonRoblox</a></li>
                                <li><a href="https://www.youtube.com/@PrestonReacts" target="_blank">PrestonReacts</a></li>
                                <li><a href="https://www.youtube.com/@ItsKeeleyElise" target="_blank">Keeley</a></li>
                                <li><a href="https://www.youtube.com/@DaisyAndPoppyYT" target="_blank">Daisy and Poppy</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="FireJob_footer-nav">
                            <h3>ABOUT TBNR</h3>
                            <ul>
                                <li><HashLink to="/#whoare">Who We Are</HashLink></li>
                                <li><HashLink to="/#FireProjects">Fire Projects</HashLink></li>
                                <li><a href="/brand-partnerships">Brand Partnerships</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="FireJob_footer-nav">
                            <h3>PRESS COVERAGE</h3>
                            <ul>
                                <li><a href="https://www.businessinsider.com/preston-creator-manager-in-house-doubled-brand-deal-revenue-2023-6">Business Insider</a></li>
                                <li><a href="https://www.tubefilter.com/2023/09/08/preston-playz-tbnr-media-company-reorganzation/">Tubefilter</a></li>
                                <li><a href="https://forbesinfo.forbes.com/e/801473/-source-Pardot-utm-content-EM1/5dfl8x/1381205640?h=1oe-YV_ABMBLVsqkMXcCTNiOkrZXzju8MWWhVO84M2A">Forbes</a></li>
                                <li><a href="https://builtin.com/awards/dallas/2024/best-places-to-work">BuiltIn</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="FireJob_footer-social">
                            <h3>CONNECT</h3>
                            <ul>
                                <li><a href="mailto:apply@firejobs.gg"><img src="images/email.png" /></a></li>
                                <li><a href="https://www.linkedin.com/company/tbnrproductions/" target="_blank"><img className="linkedImg" src="images/linked_in.png" /></a></li>
                                <li><a href="https://discord.com/invite/preston" target="_blank"><img className="youtubeImg" src="images/discord.png" /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}